import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import Logo from "@/public/static/images/logo.png";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Link from "next/link";
import { useRouter } from "next/router";
import { ClassNames } from "@emotion/react";

import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function Header() {
  const router = useRouter();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { pathname, asPath, query } = router;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [openStory, setOpenStory] = React.useState(false);
  const [openService, setOpenService] = React.useState(false);
  const [openProject, setOpenProject] = React.useState(false);

  const handleClickStory = () => {
    setOpenStory(!openStory);
  };

  const handleClickService = () => {
    setOpenService(!openService);
  };

  const handleClickProject = () => {
    setOpenProject(!openProject);
  };

  // const header: any = React.useRef<HTMLInputElement>(null);
  // const main = () => {
  //   console.log(header.current);
  //   const handleScroll = () => {
  //     if (window.scrollY > 0) {
  //       header.current.style.top = "0px";
  //       header.current.style.backgroundColor = "rgb(255,255,255,0.5)";
  //     } else {
  //       header.current.style.top = -window.scrollY + "px";
  //       header.current.style.backgroundColor = `rgb(255,255,255,${
  //         (window.scrollY / 100) * 0.5
  //       })`;
  //     }
  //   };
  //   document.addEventListener("scroll", handleScroll);
  // };
  // React.useEffect(() => {
  //   main();
  // }, []);
  return (
    <header id="back-to-top-anchor" className="header">
      {/* ref={header} */}
      <div id="smooth-content" className="container-fluid px-5">
        <div className="row">
          <Toolbar sx={{ py: 1, alignItems: "center" }} className="toolbar">
            <Box sx={{ mr: 1 }} className="d-none d-xl-flex">
              <Link href="/">
                <Image
                  loading="lazy"
                  src={Logo}
                  alt="ITP Asia"
                  className="logo my-2"
                  width={180}
                />
              </Link>
            </Box>
            <Box
              className="d-none d-xl-flex"
              sx={{
                flexGrow: 1,
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <nav className="menu">
                <ul>
                  <li className="non-ac">
                    <a href="#">Our Story</a>
                    <ul className="submenu">
                      <li>
                        <Link href="/ourstory/overview">Company Overview</Link>
                      </li>
                      <li>
                        <Link href="/ourstory/network">Our Network</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="non-ac">
                    <a href="#">Our Service</a>
                    <ul className="submenu">
                      <li>
                        <Link href="/ourservice/digital_marketing">
                          Digital Marketing
                        </Link>
                      </li>
                      <li>
                        <Link href="/ourservice/event_activation">
                          Event & Activation
                        </Link>
                      </li>
                      <li>
                        <Link href="/ourservice/web_application">
                          Website & Application
                        </Link>
                      </li>
                      <li>
                        <Link href="/ourservice/design_media">
                          Design & Media Production
                        </Link>
                      </li>
                      <li>
                        <Link href="/ourservice/sales_promotion_tools">
                          Sales Promotion Tools
                        </Link>
                      </li>
                      <li>
                        <Link href="/ourservice/localization">
                          Localization
                        </Link>
                      </li>
                      <li>
                        <Link href="/ourservice/printing_logistics">
                          Printing Production & Logistics
                        </Link>
                      </li>
                      <li>
                        <Link href="/ourservice/training_tools">
                          Training Tools
                        </Link>
                      </li>
                      <li>
                        <Link href="/ourservice/ecommerce">E-Commerce</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="non-ac">
                    <a href="#">Our Projects</a>
                    <ul className="submenu">
                      <li>
                        <Link href="/project/itpsuiteapp">
                          ITP SUITE Application
                        </Link>
                      </li>
                      <li>
                        <Link href={"https://shopee.co.th/madoojapan"}>
                          MDJ Shop
                        </Link>
                      </li>
                      <li>
                        <Link href="/project/virtualbooth">Virtual Booth</Link>
                      </li>
                      <li>
                        <Link href={"https://www.facebook.com/madoojapan/"}>
                          Madoo Japan
                        </Link>
                      </li>
                      <li>
                        <Link href="/project/ai_translation">
                          AI Translation
                        </Link>
                      </li>
                      <li>
                        <Link href="/project/itp_itic">ITP Asia x iTIC</Link>
                      </li>
                      <li>
                        <Link href={"https://www.facebook.com/prorod.official"}>
                          Prorod
                        </Link>
                      </li>
                      <li>
                        <Link href={"https://www.facebook.com/KINRAIDEEOFFICE"}>
                          Kin Rai Dee Tee Office
                        </Link>
                      </li>
                      <li>
                        <Link href="/project/welcomeapp">
                          Welcome Application
                        </Link>
                      </li>
                      {/* <li>
                        <Link href="/project/printing">Printing</Link>
                      </li> */}
                      <li>
                        <Link href="/project/premium_goods_production">
                          Premium Goods Production
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link href="/news">Blog & News</Link>
                  </li>
                  <li className="menu-5">
                    <Link href="/contact">Contact Us</Link>
                  </li>
                </ul>
              </nav>
            </Box>

            <Box sx={{ flexGrow: 0 }}></Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="d-xl-none w-100"
            >
              <Box
                className="mo-logo"
                sx={{ mr: 1, display: "flex", justifyContent: "flex-start" }}
              >
                <Link href="/">
                  <Image
                    loading="lazy"
                    src={Logo}
                    alt="ITP Asia"
                    className="logo"
                    width={100}
                  />
                </Link>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerToggle}
                  sx={{ mx: 2 }}
                  className="mb_menu"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Box>

            <Box component="nav">
              <Drawer
                className="drawer"
                variant="temporary"
                open={mobileOpen}
                anchor="right"
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { lg: "block", xl: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    maxWidth: 360,
                    width: "100%",
                  },
                }}
              >
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                  }}
                  className="nav-mo"
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Welcome to ITP Asia
                    </ListSubheader>
                  }
                >
                  <ListItemButton onClick={handleClickStory}>
                    <ListItemText className="menu-head" primary="Our Story" />
                    {openStory ? (
                      <ExpandLess sx={{ color: "#fff" }} />
                    ) : (
                      <ExpandMore sx={{ color: "#fff" }} />
                    )}
                  </ListItemButton>
                  <Collapse in={openStory} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ul className="submenu">
                        <li>
                          <Link href="/ourstory/overview">
                            Company Overview
                          </Link>
                        </li>
                        <li>
                          <Link href="/ourstory/network">Our Network</Link>
                        </li>
                      </ul>
                    </List>
                  </Collapse>

                  <ListItemButton onClick={handleClickService}>
                    <ListItemText className="menu-head" primary="Our Service" />
                    {openService ? (
                      <ExpandLess sx={{ color: "#fff" }} />
                    ) : (
                      <ExpandMore sx={{ color: "#fff" }} />
                    )}
                  </ListItemButton>
                  <Collapse in={openService} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ul className="submenu">
                        <li>
                          <Link href="/ourservice/digital_marketing">
                            Digital Marketing
                          </Link>
                        </li>
                        <li>
                          <Link href="/ourservice/event_activation">
                            Event & Activation
                          </Link>
                        </li>
                        <li>
                          <Link href="/ourservice/web_application">
                            Website & Application
                          </Link>
                        </li>
                        <li>
                          <Link href="/ourservice/design_media">
                            Design & Media Production
                          </Link>
                        </li>
                        <li>
                          <Link href="/ourservice/sales_promotion_tools">
                            Sales Promotion Tools
                          </Link>
                        </li>
                        <li>
                          <Link href="/ourservice/localization">
                            Localization
                          </Link>
                        </li>
                        <li>
                          <Link href="/ourservice/printing_logistics">
                            Printing Production & Logistics
                          </Link>
                        </li>
                        <li>
                          <Link href="/ourservice/training_tools">
                            Training Tools
                          </Link>
                        </li>
                        <li>
                          <Link href="/ourservice/ecommerce">E-Commerce</Link>
                        </li>
                      </ul>
                    </List>
                  </Collapse>

                  <ListItemButton onClick={handleClickProject}>
                    <ListItemText
                      className="menu-head"
                      primary="Our Projects"
                    />
                    {openProject ? (
                      <ExpandLess sx={{ color: "#fff" }} />
                    ) : (
                      <ExpandMore sx={{ color: "#fff" }} />
                    )}
                  </ListItemButton>

                  <Collapse in={openProject} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ul className="submenu">
                        <li>
                          <Link href="/project/itpsuiteapp">
                            ITP SUITE Application
                          </Link>
                        </li>
                        <li>
                          <Link href={"https://shopee.co.th/madoojapan"}>
                            MDJ Shop
                          </Link>
                        </li>
                        <li>
                          <Link href="/project/virtualbooth">
                            Virtual Booth
                          </Link>
                        </li>
                        <li>
                          <Link href={"https://www.facebook.com/madoojapan/"}>
                            Madoo Japan
                          </Link>
                        </li>
                        <li>
                          <Link href="/project/ai_translation">
                            AI Translation
                          </Link>
                        </li>
                        <li>
                          <Link href="/project/itp_itic">ITP Asia x iTIC</Link>
                        </li>
                        <li>
                          <Link
                            href={"https://www.facebook.com/prorod.official"}
                          >
                            Prorod
                          </Link>
                        </li>
                        <li>
                          <Link
                            href={"https://www.facebook.com/KINRAIDEEOFFICE"}
                          >
                            Kin Rai Dee Tee Office
                          </Link>
                        </li>
                        <li>
                          <Link href="/project/welcomeapp">
                            Welcome Application
                          </Link>
                        </li>
                        {/* <li>
                          <Link href="/project/printing">Printing</Link>
                        </li> */}
                        <li>
                          <Link href="/project/premium_goods_production">
                            Premium Goods Production
                          </Link>
                        </li>
                      </ul>
                    </List>
                  </Collapse>

                  <ListItemButton>
                    <Link href="/news">
                      <ListItemText primary="Blog & News" />
                    </Link>
                  </ListItemButton>
                  <ListItemButton>
                    <Link href="/contact">
                      <ListItemText primary="Contact Us" />
                    </Link>
                  </ListItemButton>
                </List>
              </Drawer>
            </Box>
          </Toolbar>
        </div>
      </div>
    </header>
  );
}

export default Header;
