import React from "react";
import Link from "next/link";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import Image from "next/image";
import Stack from "@mui/material/Stack";
import BContact from "@/public/static/images/btn-contact.png";

import GDPR from "@/components/gdpr";

import ScrollToTopFab from "@/components/ScrollToTopFab";

function Footer() {
  return (
    <section className="footer">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-12 col-lg-7 content-footer">
            <div className="h-100">
              <div className="d-flex justify-content-center align-items-center ">
                <h1>
                  Interested in our service?
                  <br />
                  Start your business with us today!
                </h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-5 content-footer">
            <div className="h-100">
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2, md: 5 }}
                justifyContent="left"
                alignItems="center"
              >
                <div className="text">
                  <h2 className="m-0">Mail us:</h2>
                  <div className="hstack gap-3 align-items-center mb-4">
                    <div className="icon">
                      <EmailIcon />
                    </div>
                    <div className="text-footer">
                      <h3 className="m-0">
                        <a href="mailto:INFO@ITP.CO.TH">INFO@ITP.CO.TH</a>
                      </h3>
                    </div>
                  </div>

                  <h2 className="mb-0">Or drop us a line:</h2>
                  <div className="hstack gap-3 align-items-center">
                    <div className="icon">
                      <CallIcon />
                    </div>
                    <div className="text-footer">
                      <h3 className="m-0">
                        +66 (0) 2-745-6050
                        <br />
                        +66 (0) 2-745-6055
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="contact-bubble">
                  <Link href={"/contact"}>
                    <Image loading="lazy"
                      src={BContact}
                      alt="Footer"
                      className="w-100 h-auto pulse"
                    />
                  </Link>
                </div>
              </Stack>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-copyright">
            <p>©2023 ITP ASIA CO., LTD. All rights reserved</p>
          </div>
        </div>
      </div>
      <ScrollToTopFab />
      <GDPR/>
    </section>
  );
}

export default Footer;
