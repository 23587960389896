import React from "react";
import Link from "next/link";
import { setCookie, getCookie } from "cookies-next";
const gdpr = () => {
  const accepted = getCookie("ITPGDPR");
  if (accepted != "accepted") {
    return (
      <section className="gdpr" id="gdpr">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 d-flex align-items-center gdpr-box">
              <div className="p-2">
                <p>
                  <span className="fw-bold">
                    ITP ASIA CO.,LTD. (referred to as ITT below) website{" "}
                  </span>
                  are using cookies to provide you a safer experience, improve
                  the performance, enable all site functionality and personalize
                  content.
                </p>
                <p>
                  By accepting our cookie policy, you agree to allow us to
                  collect information on the
                  <span className="fw-bold"> ITT website.</span>
                </p>
                <p>
                  Learn more about our
                  <a
                    className="cc-link"
                    href="/termsandconditions"
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>{" "}
                  and
                  <a className="cc-link" href="/privacypolicy" target="_blank">
                    Privacy Policy
                  </a>{" "}
                  or change your preferences on our
                  <a className="cc-link" href="/cookie" target="_blank">
                    Cookies Details Page.
                  </a>
                </p>
              </div>
              <div className="ms-auto p-2">
                <div className="d-flex justify-content-center align-items-center">
                  <a
                    className="link grow_skew_forward my-0 fs-5"
                    href=""
                    onClick={gotIT}
                  >
                    Got it!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return null;
  }
};

function gotIT(e: any) {
  e.preventDefault();
  setCookie("ITPGDPR", "accepted");
  const gdprComponent: any = document.getElementById("gdpr");
  gdprComponent.style.display = "none";
  gdpr();
}
export default gdpr;
